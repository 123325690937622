import React from 'react';
import Link from 'next/link';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const bgHeader = `${serverUrlImages}/VE/ve-header.webp`;
const sillhoutte = '/images/header-silhouette.svg';

const MainHeader = ({ element, goToHomeQuotation }) => {
	return (
			
			<div data-testid="mainHeader" className="mt-20 bg-gray-100 md:mt-10 w-full relative flex p-6 md:p-0" ref={element}>
			<div
				className="h-full w-full flex flex-col md:w-2/3 lg:w-2/3 md:z-10 md:absolute bg-cover bg-no-repeat bg-right justify-center"
				style={{ backgroundImage: `url('${sillhoutte}')` }}
			>
				<div className="w-full flex flex-col py-12 sm:py-16 max-w-90 mx-auto md:max-w-60 md:mx-20 lg:max-w-70">
					<h1 className="font-poppins font-bold text-purple-500 text-3xl lg:text-5xl leading-snug md:z-10">
						Cuida la salud de los tuyos con nuestros servicios de salud accesibles
					</h1>
					<p className="text-base lg:text-xl font-poppins leading-relaxed text-purple-500 mt-6 md:z-10 w-full lg:w-4/6">
						Protégelos con <span className="font-poppins font-bold text-pink-500">asistensi</span> frente a los malestares del día a día, las enfermedades agudas y las emergencias médicas. 
					</p>
					<div className="flex justify-between sm:justify-start mt-8">
						<button className="white-btn mr-2" onClick={() => goToHomeQuotation()}>
							<span className="select-none sm:hidden">¡Cotizar!</span>
							<span className="select-none sm:inline hidden">Quiero cotizar</span>
						</button>
						<Link
							href={{
								pathname: '/affiliation',
								query: {origin: 'affiliate'},
							}}
							as="/comenzar-afiliacion"
						>
							<button className="primary-button ml-2">
								<span className="select-none sm:hidden">¡Contratar!</span>
								<span className="select-none sm:inline hidden">Contratar ahora</span>
							</button>
						</Link>
					</div>
				</div>
			</div>
			<div className="justify-end hidden md:flex w-full">
				<div className="w-full justify-end flex overflow-hidden">
					<img
						src={bgHeader}
						alt="Seguro de emergencias médicas en Venezuela"
						name="seguro-emergencias-medicas-venezuela.png"
						className="md:w-3/5 lg:w-1/2 h-auto md:pl-20 lg:pl-0 pointer-events-none select-none"
					/>
				</div>
			</div>
		</div>
	);
};

export default MainHeader;
